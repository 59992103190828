import { useStaticQuery, graphql } from 'gatsby'
export const HostingCategoriesList = () => {
  const { allMysqlHostingcategories } = useStaticQuery(
    graphql`
      query CategData {
        allMysqlHostingcategories {
          hostingcategories:edges {
            node {
              id
              hostingtypeid
              title
              description
              metadescr
              H1Tag
              toptitle
              topdescr
            }
          }
        }
      }
    `
  )
  return allMysqlHostingcategories
}
