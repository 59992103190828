import React from 'react'
import Layout from '../components/layout'
import Top10ListDup from '../components/top10dup'
import Seo from '../components/seo'

const Top10DupPageList = ({ data }) => (
  <Layout>
    <Seo
      title={'Best Web Hosting Services for ' + new Date().getFullYear()}
      description={
        'Find best web hosting companies with money back guarantee. Top 10 web hosting companies ranked by website hosting experts. Compare best web hosts.'
      }
    />

    {/*     <center>
      <h1>Best Web Hosting Services for 2021</h1>
      <h3>
        Successful websites start with a reliable web host. Choose your web host
        wisely.
      </h3>

    </center> */}
 
    <Top10ListDup hostingid={10} onlylist={false} />
 
  </Layout>
)

export default Top10DupPageList
