import { useStaticQuery, graphql } from 'gatsby'
export const RelatedArticlesList = () => {
  const { allMysqlRelatedarticles } = useStaticQuery(
    graphql`
      query RelatedArtciclesData {

   allMysqlRelatedarticles {
    relatedarticles:edges {
        node {
          id
          articleid
          categoryid
          Title
          ArticleContent
        }
      }
    }
       
        }
      
    `
  )
  return allMysqlRelatedarticles
}
