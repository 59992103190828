import { useStaticQuery, graphql } from 'gatsby'
export const Top10DataList = () => {
  const { allMysqlTop10List } = useStaticQuery(
    graphql`
      query SiteMetaData {
        allMysqlTop10List(sort: {  fields : [rank]} ) {
          edges {
            node {
              top10id
              companyid
              hostingtypeid
              companyname
              logourl
              launchurl
              review_url
              reviewtitle
              companytitle
              detailed_description
              reviews
              features
              editorrating
              support
              rank
              price
              bonus

              features
              bonus
              moneyback

            }
          }
        }
      }
    `
  )
  return allMysqlTop10List
}
