import React from 'react'
import parse from 'html-react-parser'
import NewImage from "../components/newImage";
//import { StaticImage } from "gatsby-plugin-image"
//import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { Top10DataList } from './hooks/top10data'
import { HostingCategoriesList } from './hooks/hostingcategories'
import { RelatedArticlesList } from './hooks/relatedarticles'
import "../styles/containers.css"

const Top10ListDup = props => {

  //let params = queryString.parse(this.props.location)
  //console.log("params=", props.location)
  //console.log('hostingid=', hostingid);
  const hostingid = props.hostingid
  const onlylist = props.onlylist
  const { edges } = Top10DataList()
  const { hostingcategories } = HostingCategoriesList()
  const { relatedarticles } = RelatedArticlesList()

  //console.log('relatedarticles=', relatedarticles)
  const HostingCategory = hostingcategories.filter(
    category => category.node.hostingtypeid === hostingid
  )
  const RelatedArticleArray = relatedarticles.filter(article => {
    //console.log("article=",article, article.node.categoryid, hostingid, article.node.categoryid + "" === hostingid+"");
    return article.node.categoryid + '' === hostingid + ''
  })
  //console.log('RelatedArticleArray=', RelatedArticleArray, Object.keys(RelatedArticleArray).length )
  const category = HostingCategory[0].node

  const Top10Array = edges.filter(node => {
    //console.log(node);
    //console.log(node.node.hostingtypeid,hostingid);
    return node.node.hostingtypeid === hostingid
  })

  //console.log("edges=",edges);
  //console.log("Top10Array=",Top10Array);

  return (
    <React.Fragment key={'2222'}>
      {!onlylist &&
        (
          <React.Fragment key={'22221'}>
            {/*             <div className="splash_bgcolor"> 
              <div id="submain">
                <h1 itemProp="headline">{category.toptitle}</h1>
                <h3 itemProp="headline">{category.H1Tag}</h3>
              </div>
            </div> */}

            <center>
              <h1>{category.toptitle}</h1>  
              <h3>{category.H1Tag}</h3>
            </center>


          </React.Fragment>
        )
      }

      {Top10Array.map((node, index) => {
        const top10record = node.node
        //console.log("top10record=",top10record)
        //const logourl = top10record.logourl.replace('http://www.findbestwebhosting.com/images/', '/imagesfbwh/')

        const logourl = top10record.logourl
          .replace('http://www.findbestwebhosting.com/images/', '')
          .replace('https://www.findbestwebhosting.com/images/', '')
          .replace('gif', 'png')
        //console.log("top10record.review_url=",top10record.review_url );

        //const fullimgpath= `../images/` + logourl;

        const companyname_no_spaces = top10record.companyname.toLowerCase()
        //const reviewTitle =  companyname_no_spaces + ' review'
        const removeStr = ' ' //variable
        const regex = new RegExp(removeStr, 'g') // correct way
        //let reviewPath = !!top10record.reviews? reviewTitle.replace(regex, '-') :"" // it works
        let reviewPath = !!top10record.reviews? top10record.reviewtitle :"" // it works
        //reviewPath = top10record.reviewtitle

        const companyname_with_dashes = companyname_no_spaces.replace(regex, '-') // it works
        const companyname_with_dashes_lc = companyname_with_dashes.toLowerCase()
        reviewPath = !!top10record.review_url ? "" : (!!reviewPath ?         
        "/" + reviewPath : "")
        //console.log("top10record.reviews=",top10record.reviews, ", reviewPath=", reviewPath, ", top10record.review_url=",top10record.review_url)
        const detailed_description = !!top10record.detailed_description ? parse(top10record.detailed_description+" ")   : "";
        //console.log("detailed_description=",top10record.reviews, ", reviewPath=", reviewPath, ", top10record.review_url=",top10record.review_url)

        const editorRating = !!top10record.editorrating? top10record.editorrating + 'star.png' : ""

        return (
          <React.Fragment key={`${top10record.top10id}`}>
        

            <div className="3colwrapper">
              <section className="threecolumns">

                <div className="eachcolumn">

          
       
                  <a target="mew" rel="nofollow"
                    href={`/visit-web-host.aspx/${companyname_with_dashes}/${top10record.top10id
                      }`}
                  >

                     <NewImage
                      src={`${logourl}`}
                      alt={`Signup for  ${top10record.companytitle}`}

                    />  
 



                  </a>
                

                    {<div>{(detailed_description)}</div>} 

                    {  !!top10record.review_url && 
                    <div>
                      <a target="new" href ={`${top10record.review_url}`}  >
                        {`${top10record.companytitle}`}
                      </a>
                    </div>
                    }
                    
                    {  !!reviewPath && 
                    <div>
                      <Link to={`${reviewPath}`}  >
                        {`${top10record.companytitle}`}
                      </Link>
                    </div>
                    }
                   {/*<br/>companyid: {top10record.companyid} */}
                </div>

                {/* 2nd col */}

                <div className="eachcolumn grey">
                  <h2>Why {`${top10record.companyname}`} ?</h2>
                  <div>{parse(`${(top10record.features)}`)} </div>
                  { !!editorRating && <div>Editor's Rating:    

                  <NewImage
                      src={`${editorRating}`}
                      alt={`Rating for  ${top10record.companytitle}`}

                    />
                    </div>
                  }
                </div>

                {/* 3rd col */}
                <div className="eachcolumn grey">
                  <h2>Just ${`${top10record.price}`} / Month</h2>
                  <div>{parse(`${(top10record.bonus)}`)} </div>
                  <div>
                    <a target="mew" className="btnctagreen" rel="nofollow" href={`/visit-web-host.aspx/${companyname_with_dashes_lc}/${top10record.top10id
                      }`}
                    >
                      Get Started
                    </a>
                    </div>
                </div>

              </section>
            </div>




          </React.Fragment>
        )
      })}


      {!onlylist &&
        Object.keys(RelatedArticleArray).length > 0 && (
          <React.Fragment key={`111111`}>
            <h2>{RelatedArticleArray[0].node.Title}</h2>
            <div>{parse(RelatedArticleArray[0].node.ArticleContent)}</div>
          </React.Fragment>
        )}

    {/*"id=" + hostingid */}
    </React.Fragment>

  

  )
}

export default Top10ListDup
