import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types";

const NewImage = ({ src, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile  {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {           
              gatsbyImageData(
                quality: 90               
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.images.edges.find(({ node }) => {


      return src === node.relativePath

    }),
    [data, src]
  );

  if (!match) return null;

  const { node: { childImageSharp, publicURL, extension } = {} } = match;

  if (extension === "svg" || !childImageSharp) {
    return <img src={publicURL} {...rest} />;
  }

  const image = getImage(childImageSharp.gatsbyImageData)
  //console.log("image=", image )

  //return <Img fixed={childImageSharp.fixed} {...rest} />;

  return  <GatsbyImage image={image} {...rest} />


};

NewImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default NewImage;